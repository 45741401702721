<template>
  <div class="login-container">
    <img
      class="BKG"
      src="https://yihui-1322438817.cos.ap-shanghai.myqcloud.com/mall/76276089143.webp"
    />

    <div class="center-box">
      <div class="login-box">
        <div class="login-form">
          <div class="logo">
            <div>
              <span class="brand">YIHOI</span>
              <br />
              艺烩后台管理系统
            </div>
          </div>
          <div class="title_l">登录!</div>
          <div v-if="isActive === 1">
            <el-input
              v-model="account"
              placeholder="手机号"
              class="account"
              maxlength="11"
              clearable
            ></el-input>
            <!-- 获取验证码 -->
            <div style="position:relative">
              <el-input
                v-model="verCode"
                placeholder="验证码"
                class="verCode"
                clearable
              >
              </el-input>
              <el-button
                type="primary"
                class="getCode"
                @click="getCode"
                :disabled="disabled"
                >{{ codeBtnTitle }}</el-button
              >
            </div>
          </div>

          <div v-else>
            <el-input
              v-model="account"
              placeholder="请输入账号"
              class="account"
              maxlength="11"
              clearable
            ></el-input>
            <el-input
              placeholder="请输入密码"
              :type="flag ? 'text' : 'password'"
              v-model="password"
              maxlength="18"
              class="account"
              clearable
            >
              <i
                slot="suffix"
                :class="[flag ? 'el-icon-sunny' : 'el-icon-cloudy']"
                class="password_icon"
                autocomplete="auto"
                @click="flag = !flag"
              />
            </el-input>
          </div>

          <div class="rememberBtn">
            <el-checkbox v-model="passwordChecked"
              >记住{{ isActive === 0 ? "账号密码" : "手机号" }}</el-checkbox
            >
          </div>

          <el-button type="primary" class="loginBtn" @click="loginBtn" v-waves
            >登录</el-button
          >
        </div>
      </div>

      <!-- 标语 -->
      <div class="life">
        <div class="footer">上海艺烩信息技术有限公司提供技术支持</div>
        <div class="footer">
          Powered by YiHoi Ltd.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "@/services/user/index";
import { mapActions } from "vuex";

export default {
  computed: {},
  created() {
    //1记住密码  0不记住密码
    if (localStorage.getItem("passwordChecked") == 1) {
      this.passwordChecked = true;
      // 账号回显
      var loginForm = JSON.parse(localStorage.getItem("loginForm"));
      // console.log(loginForm);
      if (loginForm !== null) {
        this.account = loginForm.account;
        this.password = loginForm.password;
      }
    } else {
      this.passwordChecked = false;
    }
  },
  data() {
    return {
      flag: false,
      account: "", //用户名或手机号
      verCode: "", //验证码
      password: "", //密码
      passwordChecked: "",
      codeBtnTitle: "验证码",
      disabled: false,
      loginStyle: ["账号登录", "手机号登录"],
      isActive: 0,
    };
  },
  watch: {},
  methods: {
    ...mapActions(["getUserInfoAction"]),
    activeBtn(i) {
      this.isActive = i;
    },
    /* 计时器 */
    timer() {
      let s = 60;
      this.codeBtnTitle = s + " s";
      this.disabled = true;
      let timer = setInterval(() => {
        s--;
        this.codeBtnTitle = s + " s";
        if (s === 0) {
          clearInterval(timer);
          this.codeBtnTitle = "验证码";
          this.disabled = false;
        }
      }, 1000);
    },
    /* 获取验证码 */
    getCode() {
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      // console.log(reg_tel.test(this.account));
      if (!reg_tel.test(this.account)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
    },
    /* 登录 */
    loginBtn() {
      // var password = md5(this.password);
      var verCode = this.verCode;
      var password = this.password;
      var phoneNumber = this.account;
      // 记住密码监听
      if (this.passwordChecked) {
        //将用户数据存到localStorage 长期有效 （cookie不设置时效关闭浏览器就消失了 设置才长期有效）
        localStorage.setItem("passwordChecked", 1);
        var loginForm = {
          account: this.account,
          password: this.password,
        };
        localStorage.setItem("loginForm", JSON.stringify(loginForm));
      } else {
        localStorage.setItem("passwordChecked", 0);
        localStorage.removeItem("loginForm");
      }

      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(this.account)) {
        this.$message.error("请输入正确的手机号");
        return;
      }

      if (this.isActive === 0) {
        if (phoneNumber === "" || password === "") {
          this.$message.error("账号或密码不能为空");
          return;
        }
      }
      if (this.isActive === 1) {
        if (phoneNumber === "" || verCode === "") {
          this.$message.error("手机号或验证码不能为空");
          return;
        }
      }
      login({
        phoneNumber,
        password,
      })
        .then((res) => {
          if (res.code === 200) {
            this.getUserInfoAction().then((res) => {
              if (res.code === 200) {
                this.$message.success("登录成功");
              } else {
                this.$message.error(res.message);
              }
              this.$router.push({ name: res.routerName });
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error("网络异常");
        });
    },
  },
};
</script>


<style lang="less" scoped>
.login-container {
  background: rgb(255, 255, 255);
  height: 100%;
  width: 100%;
  position: fixed;
  .BKG {
    width: 100%;
    height: 100vh;
    opacity: 0.2;
  }
  .center-box {
    min-height: 420px;
    width: 450px;
    height: 550px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    animation-name: move;
    animation-duration: 1s;
    display: flex;
  }
  @keyframes move {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

/* 右边登录 */
.login-box {
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 30px;
  box-shadow: 0 0 10px 0 rgba(88, 88, 88, 0.1);
  .login-form {
    position: absolute;
    top: 25%;
    width: 80%;
    margin: 0 auto;
    left: 0;
    right: 0;
    padding: 10px 30px;
    border-radius: 20px;
    .logo {
      position: absolute;
      top: -25%;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      font-size: 1.2em;
      letter-spacing: 2px;
      font-weight: 800;
      height: 60px;
      text-align: center;
      justify-content: center;
    }
    .brand {
      font-size: 22px;
    }
    .title_l {
      margin: 20px 0;
      font-weight: 800;
      font-size: 1.2em;
    }
    .password_icon {
      position: absolute;
      font-size: 16px;
      right: 30px;
      top: 12.5px;
    }
    .password_icon:hover {
      cursor: pointer;
      color: #111111ca;
    }
    /* 登录方式切换 */
    .loginStyle {
      display: flex;
      width: 100%;
      margin: 10px auto;
      color: #6b6b6b;
    }
    .active {
      color: #afc7d6 !important;
      font-weight: 550;
    }

    .loginStyle > div {
      margin: 20px 15px 20px 0px;
    }
    .loginStyle > div:hover {
      cursor: pointer;
    }
    .account {
      margin: 20px auto;
      width: 100%;
      display: block;
      position: relative;
    }
    .verCode {
      display: block;
      margin: 10px auto;
      width: 100%;
    }
    .getCode {
      position: absolute;
      top: 0px;
      right: 10%;
      width: 85px;
    }
    .rememberBtn {
      margin: 0 auto;
      width: 100%;
    }
    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #c4c9aa !important;
      border-color: #c4c9aa !important;
    }
    /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #c4c9aa;
    }
    .loginBtn {
      background: #c4c9aa;
      margin: 20px auto;
      width: 100%;
      display: block;
      border: none;
    }
  }
}

/* 标语 */
.life {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -80px;
  text-align: center;
  font-weight: 200;
  .footer {
    letter-spacing: 3px;
    color: #7f7f7f;
  }
}
</style>

// selectProductData({ phoneNumber: this.account }) // .then((res) => { // //
console.log(res); // if (res.data.code === 200) { // //后端协商的判断条件 //
this.$message.success("验证码已发送，注意查收！"); // this.timer(); //启动倒计时
// } else { // this.$message.error(res.data.msg); // } // }) // .catch((err) =>
{ // this.$message.error("网络异常"); // setTimeout(() => { //
this.fullscreenLoading = false; // }, 1000); // });
